.noti-modal-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: #f4f5f7a8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.noti-modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    width: 900px;
    background: white;
}

.noti-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 200px;
    padding-bottom: 20px;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
}

.noti-modal-wrap .btn-holder{
    display: flex;
    justify-content: center;
}
.noti-modal-button {
    margin: 4px 0 20px 0;
    padding: 10px 30px;
    font-size: 23px;
    text-align: center;
    background-color: #29a7df;
    width: 70px;
}
