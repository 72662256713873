.rectangle-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0px 12px;

  &:hover {
    background-color: #29a7df;
  }
}