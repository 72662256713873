.login-screen {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h1 {
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 20px;
        color: #333;
    }

    form {
        display: flex;
        flex-direction: column;

        .form-group {
            margin-bottom: 15px;

            label {
                display: block;
                font-size: 0.9rem;
                margin-bottom: 5px;
                color: #333;
            }

            input {
                width: 96%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 1rem;
                outline: none;

                &:focus {
                    border-color: #007bff;
                    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
                }
            }

            .error-message {
                margin-top: 5px;
                font-size: 0.85rem;
                color: #d9534f;
            }
        }

        .buttons-wrapper {
            margin-top: 20px;
            display: flex;
            justify-content: center;


        }
    }
}