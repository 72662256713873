.message-wrapper { 
    width: 100vw;
    height: 100vh; 
    display: flex;
    position: absolute;
    top:0;
    right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff9e;



    .message-content{
        padding:50px 40px 60px 40px;
        border: solid 3px #f59b14 ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
    }
    

    .message-text{
        font-size:22px; 
        font-weight: bold;
        padding: 22px;
    }

    .message-icon{
        img{
            width: 130px;
            padding: 30px 20px
        } 
    }

    .message-button{
        font-size:22px; 
        font-weight: bold;
        background-color:#f59b14;
        background-color:#0b71b8;
        border-radius: 54px;
        padding: 30px 20px;
        color: #fff;
        cursor: pointer;
    }
}
.message-wrapper.success{
    font-size:23px; 
}