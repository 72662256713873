.input-field {
    label {
        display: block;
        font-weight: bold;
        font-size: 18px;
        color: #555;
    }

    input {
        width: 80vw;
        max-width: 400px;
        font-size: 20px;
        padding: 8px;
        border-radius: 5px;
        margin: 3px;
    }
}