.stations-page-wrapper {
    width: 90vw;


    #stations-section-container {
        font-family: Roboto;
        font-size: 0.8rem;
    }

    #stations-section-body {
        background-color: white;
    }

    #stations-section-menu {
        padding: 15px 0;
        margin: 0 0 20px 0;
        font-size: 1rem;
    }

    .stations-section-menu-item {
        display: inline-block;
        margin: 0 20px;
    }

    .chkbx-and-label-wrapper {
        justify-content: right;
        align-items: self-start;
        margin: 3px 0;
    }

    #station-section-lockers-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .stations-section-locker-block {
        display: inline-block;
        padding: 10px;
    }

    .ss-label {
        display: block;
        color: #4c4c4c;
        font-size: 1rem;
    }

    .ss-input {
        /* direction: ltr; */
        border: 1px solid gray;
        border-radius: 3px;
        font-size: 1rem;
        text-transform: capitalize;
        margin: 0 0 0 20px;
        padding: 3px;
    }

    .ss-container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 20px;
    }

    .ss-line {
        display: flex;
        justify-content: space-between;
    }

    .ss-group {
        display: inline-block;
        margin: 5px 0 5px 0;
    }

    .ss-button {
        font-family: inherit;
        text-transform: capitalize;
        border: 0;
        border-radius: 5px;
        padding: 5px;
        margin: 10px 0;
        width: 100px;
        background-color: #1076ba;
    }

    .comma-separator {
        font-size: 22px;
        vertical-align: bottom;
        margin-left: 18px;
    }

    .textarea-container .ss-group textarea.ss-input {
        width: auto;
        height: auto;
    }

    .container-select .ss-input {
        min-width: 182px;
    }

    .payment-version-wrapper .css-b62m3t-container {
        width: 174px;
    }

    #stations-list-table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    #stations-list-table tbody tr {
        border-bottom: 1px solid gray;
    }

    #stations-list-table td {
        padding: 5px;
    }

    #stations-list-table th {
        background: #eee;
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        position: sticky;
        top: 0;
        font-weight: 700;
        text-align: start;
        padding: 5px;
    }

    tr:hover {
        background: #eee;
    }

    .station-editor-spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
    }

    .stations-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .export {
            margin-right: 12px;
        }

        .stations-export {
            padding: 6px 10px;
            margin: 8px 0 0 0;
            padding: 6px 10px;
            margin: 8px 0 0 0;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: #3498db !important;
            color: #ffffff;
            transition: background-color 0.3s, color 0.3s;
        }
    }

}