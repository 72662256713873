$primary-text-color: #333;
$input-border-color: #ccc;
$input-focus-color: #4a90e2;
$background-color: #f5f5f5;
$button-bg-color: #4a90e2;
$button-text-color: white;

.locker-to-locker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0 40px 0;
    padding: 0 40px 24px 40px;
    border-radius: 8px;
    background-color: $background-color;
    direction: rtl;
    font-family: Arial,
        sans-serif;

    .customers-wrapper {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .cutomer-source,
        .cutomer-target {
            display: flex;
            flex-direction: column;
        }
    }

    .order {
        width: 44%;
    }

    .source,
    .target {
        min-height: 212px;
    }

    .person-ditails-wrapper {
        margin-bottom: 20px;
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .sub-title {
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 10px;
            color: $primary-text-color;
        }

        .input-wrapper {
            margin-bottom: 10px;

            .input-title {
                display: block;
                margin-bottom: 5px;
                font-size: 18px;
                width: 10rem;
                color: $primary-text-color;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            .input-text {
                width: 100%;
                padding: 8px;
                border: 1px solid $input-border-color;
                border-radius: 4px;
                font-size: 14px;

                &:focus {
                    outline: none;
                    border-color: $input-focus-color;
                    box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
                }

                &:disabled {
                    background-color: #f0f0f0;
                    cursor: not-allowed;
                }
            }
        }

        .station-selector {
            width: 100%;

            .select__control {
                border-radius: 4px;
                border-color: $input-border-color;
            }

            .select__indicator-separator {
                display: none;
            }
        }
    }

    .validation-msg {
        color: red;
        text-align: center;
        margin: 10px 0;
        font-size: 14px;
    }

    .cror-modal-status {
        display: flex;
        justify-content: center;
        gap: 15px;

        .cror-modal-button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            background-color: $button-bg-color;
            color: $button-text-color;
            cursor: pointer;
            font-size: 14px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($button-bg-color, 10%);
            }
        }
    }
}