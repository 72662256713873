.yesno-modal-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: #f4f5f7a8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.yesno-modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    width: 900px;
    background: white;
}

.yesno-modal-content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 200px;
    padding-bottom: 20px;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
}

.yesno-modal-btns-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 100px;
}

.yesno-modal-button {
    margin: 0;
    padding: 10px 30px;
    font-size: 23px;
}