.sml-header-wrapper {
    direction: rtl;
    display: flex;
    flex: 1;
    border-radius: 8px;
    padding: 8px 55px;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: var(--header-color);

    .sml-header-title {
        font-size: 20px;
        font-weight: 600;
    }
}