.stations-table-wrapper {
    margin: 20px auto;
    overflow-x: auto;

    .stations-table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
        font-size: 16px;
        text-align: right;

        th {
            background-color: #f4f4f4;
            color: #333;
            cursor: pointer;
            padding: 10px 15px;
        }

        th:hover {
            background-color: #e2e2e2;
        }

        td {
            padding: 10px 15px;
            border-bottom: 1px solid #ddd;
        }


        .status-btn {
            padding: 5px 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }

        .status-btn:hover {
            background-color: #0056b3;
        }

        .no-data {
            text-align: center;
            font-style: italic;
            color: #999;
        }

        i {
            font-size: 24px;
        }

        .green {
            color: rgb(51, 172, 67);

        }

        .red {
            color: rgb(216, 38, 7);
        }
    }

}