.pagin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 50px;
}

.pagin-btn {
    margin: 0 2px;
    font-size: 18px;
    font-weight: 600;
    background-color: #29a7df;
    border:none;
    color: #ffff;
}

.pagin-btn:hover {
    border: none;
    background-color: #1876BA;
}

.pagin-selected {
    background: #1076ba;
    
}