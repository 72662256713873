.record-table {
  color: #000;
  width: 630px;
  height: 300px;
  overflow-y: auto;
  font-size: 16px;
  margin-top: 20px;
}
.record-row {
  display: block;
}

.record-row:nth-child(even) {
  background-color: #f2f2f2;
}
.record-col {
  display: inline-block;
  padding: 0 0 0 8px;
  border-left: dotted 2px #eee;
  width: 168px;
  vertical-align: middle;
  font-weight: 700;
}
.record-col.icon {
  width: 22px;
}
