.cell-wrapper {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  width: 80px;
  background: white;
  font-family: AlmoniTzar;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.1;
  border: solid 1px #ff5726;
  border-radius: 6px;
  padding: 0 10px;
  margin: 5px;
}

.cell-with-order {
  padding: 5px 10px;
  font-size: 20px;
  height: 100px;
}

.cell-text-wrapper {
  display: flex;
  color: black;
  flex-direction: column;
}

.cell-order-details-wrapper {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}
.cell-number {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  font-size: 32px;
}

.cell-type-img {
  background: #ff5726;
  padding: 0 10px;
}

.cell-img-icon {
  width: 50px;
}

@keyframes blink {
  25% {
    background: #ff5726;
  }
  75% {
    background: #f3eee8;
  }
}

.cell-blink {
  animation: blink 2.5s ease-in-out 0s infinite;
}

@keyframes errorblink {
  25% {
    background: #e21710;
  }
  75% {
    background: #f3eee8;
  }
}

.cell-error-blink {
  animation: errorblink 2.5s ease-in-out 0s infinite;
}

.cell-red {
  background: #ec6b6b;
}

.cell-green {
  background: #7ed592;
}

.cell-black {
  background: #302a2c;
  color: white;
}

.cell-grey {
  background: #969595;
}

.cell-blue {
  background: #93c5c5;
}
