
.new-user {
  display: flex;
  flex-direction: column;
}
.new-user .ss-group{
  display: flex;
  flex-direction: column;
}
.new-user .ss-button{
  text-align: center;
    padding: 6px 0px;
}
.new-user .btn-holder{
display: flex;
justify-content: center;
}
.new-user .content-loading,
.new-user .content-message {
  display: block;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding: 100px 50px;
  font-size: 24px;
  font-weight: bold;
  color: #2faadf;
}

.new-user .content-message {
  color: #fda73a;
}


.new-user .ss-button {
  width: 180px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.new-user .field-valid {
  margin: 0 0 5px 0;
  display: inline-block;
}

.new-user.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 60vw;
  overflow: hidden;
  animation: spinnerRotate 6s linear infinite;
}
