.lockcell-wrapper {
  direction: ltr;
  display: flex;
  justify-content: flex-start;
  margin: auto;
  max-height: 100%;
  overflow: auto;
  padding: 0.1rem 2rem;
  user-select: none;
  width: -webkit-fit-content;
  max-width: 1400px;
  overflow-y: scroll;
}
.centered {
  justify-content: center;
}

.lockcell-minified {
  max-width: 400px;
}

.lockcell-col-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lockcell-wrapper::-webkit-scrollbar-thumb {
  background-color: #fc5726;
  border-radius: 11px;
}
.lockcell-wrapper::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}
.lockcell-wrapper::-webkit-scrollbar-track:horizontal {
  background: rgba(194, 194, 194, 0.23);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  background-clip: padding-box;
}
.lockcell-wrapper::-webkit-scrollbar {
  width: 13px;
}
.lockcell-wrapper::-webkit-scrollbar:horizontal {
  height: 13px;
}
