.stations-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    .current-station {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 10px;
    }

    >div {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        min-width: 26vw;

        .btn-holder {
            margin: 20px 0;

            button {
                margin: 5px;
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;

                &:first-child {
                    background-color: #007bff;
                    color: #fff;

                    &:hover {
                        background-color: #0056b3;
                    }
                }

                &:last-child {
                    background-color: #dc3545;
                    color: #fff;

                    &:hover {
                        background-color: #e2e6ea;
                    }
                }
            }
        }

    }
}