.menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    margin: 0;
    z-index: 100;

    color: rgb(39, 135, 245);

    .menu-items-wrapper {
        border-left: solid 1px #ccc;
    }
}