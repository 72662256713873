.orderDelayModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 6;

    &.open {
        display: block;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        width: 100%;
        text-align: center;
        position: relative;

        h2 {
            margin: 0 0 16px 0;
        }

        p {
            margin-bottom: 20px;
        }


        .btn-holder {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px;

            button {
                margin: 4px 12px 12px 6px;
                padding: 6px 12px;
                font-size: 24px;
            }

        }

    }


    .react-datepicker-wrapper {
        input {
            margin-bottom: 12px;
            font-size: 18px;
        }
    }
}