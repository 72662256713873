.noti-modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(244, 245, 247, 0.66);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.noti-modal-container {
    display: flex;
    flex-direction: column;
    width: 840px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 3px 9px 16px rgba(0, 0, 0, 0.7);
    outline: none;
}

.noti-modal-content-wrapper {
    padding: 50px 200px 20px;
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
}

.noti-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.noti-modal-button {
    margin: 0 4px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.noti-modal-button:hover {
    background-color: #0056b3;
}

.noti-modal-button:focus-visible {
    outline: 2px solid #0056b3;
    outline-offset: 4px;
}