.create-return-order {
  .multi-autocomplete-wrapper {
    font-size: 20px;
    border-radius: 5px;
    margin: 3px;
    max-width: 500px;
    padding: 0;

    .multi-select__control {
      border: 2px solid;
    }
  }

  .station-number-error {
    display: inline-block;
    position: relative;
    padding: 0 4px 18px;
    color: red;
    font-weight: bold;

    .fa-times-circle {
      font-size: 22px;
      margin-left: 6px;
    }
  }

  .simple-select__control {
    border: 2px solid black;
    font-weight: bold;

    .simple-select__placeholder .css-14el2xx-placeholder {
      padding: 8px 2px;
    }

    //.simple-select__css-1s2u09g-control {}
  }

  .fileInputButton {
    display: inline-block;
    padding: 10px 14px;
    color: #fff;
    background-color: #1076ba;
    border: 1px solid #1076ba;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin: 0 5px;
    font-size: 16px;
    line-height: 1;
  }

  .failed-orders {
    color: red;
  }
}