.open-menu-btn {
    .hamburger-menu {
        width: 40px;
        height: 30px;
        margin: 12px 12px 0 0;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.3s ease;

        .hamburger-line {
            width: 100%;
            height: 4px;
            background-color: #0b71b8;
            border-radius: 2px;
            transition: all 0.3s ease;
            transform-origin: center;
        }

        &.active {
            .top {
                transform: rotate(45deg) translate(10px, 10px);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                transform: rotate(-45deg) translate(8px, -8px);
            }
        }

        &:hover {
            .hamburger-line {
                background-color: #666;
            }
        }
    }
}