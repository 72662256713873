.modal {
    position: fixed;
    display: block;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccf;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
}

.cror-modal-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: rgba(244, 245, 247, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  
  .cror-modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    background: white;
    direction: rtl;
  }
  
  .cror-modal-title {
    font-size: 30px;
    color: #1076ba;
    font-weight: 600;
  }
  
  .cror-modal-inputs-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 70px;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }

  .cror-modal-status {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }
  
  