.spinner-wrapper {
    position: relative;
    /* width: 150px;
    height: 150px; */
    animation: spinnerRotate 2s linear infinite;
}

@keyframes spinnerRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 10;
    stroke: #639CF6;
    transform: translate(5px, 5px);
    stroke-linecap: round;
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    animation: animateSpinner 4s linear infinite;
}

@keyframes animateSpinner {
    0%, 100% {
        stroke-dashoffset: 440;
    }
    50% {
        stroke-dashoffset: 0;
    }
    50.1% {
        stroke-dashoffset: 880;
    }
}