.button {
  margin: 0 5px;
  background-color: #3498db;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #2980b9;
  }

  &.warning {
    background-color: #f39c12;

    &:hover {
      background-color: #f39c12;
    }
  }

  &.success {
    background-color: #2ecc71;

    &:hover {
      background-color: #27ae60;
    }
  }

}