.TableContainer {
  .pagination {
    max-width: 1000;
    margin: 0px 0 1rem 0;
    text-align: "center";
  }

  .pagination div {
    display: inline-block;
  }

  .export-button {
    color: #fff;
    background-color: rgb(39, 135, 245);
    box-shadow: 1px 1px 1px rgb(14, 56, 105);
    border: rgb(14, 56, 105) solid 1px;
    font-weight: 600;
    margin: 0 0.3rem 0.5rem 0;

    &:active {
      margin-left: 1px 1px 0;
      box-shadow: -1px -1px 1px #000;
    }
  }

  .barcod_loading {
    margin-right: 6px;
    font-weight: bold;
  }
}