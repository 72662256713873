.order-list-l2l-wrapper {
    display: block;
    justify-content: center;

    .order-list-l2l {
        margin: auto;
        overflow: auto;
        max-width: 98vw;

        .table-container {
            table {
                max-width: 98vw;
                max-height: 64vh;
                display: block;
                overflow: scroll;

                thead {
                    background-color: #ddd;

                    input,
                    select {
                        font-size: 16px;
                    }
                }

                input:focus {
                    border: 2px solid #f59b14;
                }

                tr:nth-child(even) {
                    background-color: #ddd;
                }

                th:nth-child(4) {
                    input {
                        width: 160px;
                    }
                }

                th:nth-child(5) {
                    input {
                        width: 100px;
                    }
                }

                th:nth-child(8) {
                    input {
                        width: 160px;
                    }
                }

                th:nth-child(6) {
                    input {
                        width: 140px;
                    }
                }

                th:nth-child(13) {
                    input {
                        width: 160px;
                    }
                }

                th:nth-child(14) {
                    input {
                        width: 140px;
                    }
                }

                tr:hover {
                    background-color: #f59b14af;
                    cursor: pointer;
                }

                td {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;

                    .order-edit {
                        margin-top: 5px;
                        width: 20px;
                    }
                }


            }
        }
    }

    .list-counter {
        padding-right: 2px;
        font-size: 16px;
        font-weight: bold;
        color: #8a8a8a;
        width: 160px;
    }
}


@media screen and (max-width: 1600px) {
    .order-list-wrapper .order-list .table-container table {
        max-width: 98vw;
        display: block;
        max-height: 60vh;
        overflow: scroll;
    }
}