.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}
.react-pdf__Document {
  max-height: 400px;
  overflow: hidden;
  align-items: center;
  border: solid 1px #000;
  margin-top: 20px;
}

.mx-3 {
  margin-left: 1rem !important;
}
.w-100 {
  width: 100% !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.disabled {
  cursor: not-allowed;
  color: lightgray;
}
.fa,
.fas {
  font-weight: 900;
}
