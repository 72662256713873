.packageDebitReport {
    .date-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
    }

    .label-box {
        display: flex;
        flex-direction: column;
        font-size: 16px;

        .ss-input {
            font-size: 18px;
            margin: 4px 0px;
            padding: 4px 20px;
        }
    }

    .btn-download-wrapper {
        display: flex;
        justify-content: center;

        .download-file-button {
            background-color: #1076ba;
            border: none;
            width: 150px;
            height: 40px;
            color: white;
            padding: 7px 15px;
            border-radius: 5px;
            box-sizing: border-box;
            font-size: inherit;
            font-family: inherit;
            text-align: center;
            display: inline-block;
            cursor: pointer;
        }
    }

}